import React, { useEffect } from 'react';

const PlatformRedirect = () => {
  useEffect(() => {
    const detectAndRedirect = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const redirectWithDelay = (url) => {
        setTimeout(() => {
          window.location.href = url;
        }, 2000); // 1-second delay
      };

      // Redirect to Google Play for Android devices
      if (/android/i.test(userAgent)) {
        // window.location.href = "https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user";
        redirectWithDelay("https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user");
        return;
      }

      // Redirect to App Store for iOS devices
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // window.location.href = "https://apps.apple.com/us/app/forunpoint/id1630196197";
        redirectWithDelay("https://apps.apple.com/us/app/forunpoint/id1630196197");
        return;
      }

      // Redirect to a fallback or home page for non-Android/iOS devices
      window.location.href = "https://forunpoint.com.pk";
    };

    detectAndRedirect();
  }, []);

  return (
    <div>
      Redirecting...
    </div>
  );
};

export default PlatformRedirect;
