// JoinOptions.js
import React from 'react';
import './join-us-compo.css';
import supermanHotel from '../../assets/images/hotel.png'
import shaktiman from '../../assets/images/shaktiman.png'

const JoinOptions = () => {
  return (
    <div className="join-options-container">
      {/* Join as Restaurant */}
      <img
        src={supermanHotel}
        alt="Restaurant Icon"
        className="option-image"
      />
      <div className="option">
        <h2 className='compo-title'>Join as Restaurant</h2>
      </div>
      <a href="restaurant-registration" style={{ textDecoration: 'none' }}>
        <button className="signup-button">Sign Up</button>
      </a>

      {/* <button className="signup-button">Sign Up</button> */}
      {/* <a href="/join-us">Join as Restaurant</a> */}

      <div className="divider"></div>

      {/* Join as Rider */}
      <img
        src={shaktiman}
        alt="Restaurant Icon"
        className="option-image2"
      />
      <div className="option">
        {/* <img
          src="rider-icon.png"
          alt="Rider Icon"
          className="option-image"
        /> */}
        <h2 className='compo-title'>Join as Rider</h2>
      </div>
      <a href="/driver-registration" style={{ textDecoration: 'none' }}>
        <button className="signup-button">Join Us</button>
      </a>
      {/* <button className="signup-button">Sign Up</button> */}
      {/* <a href="/driver">Join as Rider</a> */}

    </div>
  );
};

export default JoinOptions;
