import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HomePage from "./componentes/home/homepage";
import Footer from "./componentes/Footer/footer";
import '@fortawesome/fontawesome-free/css/all.css';
import Facebook from "./componentes/tracking/Facebook";
import LandingGif from "./componentes/landing-gif/landing-gif";
import SubFooter from './componentes/sub-footer/sub-footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export const ConvertPKRtoUSD = (price) => {
  return `Rs ${Math.round(price)}`;
};
function App() {
  const [location, setLocation] = useState(localStorage.getItem('country'));
  const [error, setError] = useState(null);
  const [convertedPrice, setConvertedPrice] = useState(null);
  const redirectCountries = ['US', 'CA', 'GB', 'AU', 'AE', 'SA'];


  const fetchLocation = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`);
      const country = response.data.country;
      // const country = "US"; // Replace with actual API call
      const storedCountry = localStorage.getItem('country');

      if (storedCountry !== country) {
        localStorage.setItem('country', country);
        setLocation(country);
      }

      if (redirectCountries.includes(country)) {
        window.location.href = "https://forunpoint.com";
      }
    } catch (error) {
      setError('Could not determine location.');
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (location === null) {
    return null;
  }

  if (location === 'PK') {
    return (
      <div>
        <Facebook />
        <LandingGif />
        <HomePage />
        <SubFooter />
        <Footer />
      </div>
    );
  } else {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
      }}>
        {/* <div>
          <p style={{
            padding: '20px',
            borderRadius: '10px',
            color: '#333',
            fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
            fontSize: '18px',
            color: '#d4110f',
            fontSize: 'calc(10px + 1vw)',
            fontWeight: '500'
          }}>
            if(redirectCountries.includes(country)){
              Redirectin you to www.forunpoint.com
            }else{

              Sorry...!
            <br />
            We are not offering our services in your country.
          }
          </p>
        </div> */}
        <div>
          <p
            style={{
              padding: '20px',
              borderRadius: '10px',
              color: '#333',
              fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
              fontSize: 'calc(10px + 1vw)',
              fontWeight: '500',
            }}
          >
            {redirectCountries.includes(location) ? (
              <>Redirecting you to forunpoint.com</>
            ) : (
              <>
                Sorry...!
                <br />
                We are not offering our services in your country.
              </>
            )}
          </p>
        </div>

      </div>
    );
  }
}

export default App;