import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import CookieConsent from "../../cookies-consent/cookies-consent";
import "./LandingHome.css";
import Autocomplete from '../Autocomplete/Autocomplete';
import salad from '../../../assets/images/salad.png'
import landingImg from '../../../assets/images/landing-img.png'
import forunpointText from '../../../assets/images/forunpoint-text.png'
import rider from '../../../assets/images/rider.png'
import JoinOptions from "../../join-us-compo/join-us-compo";
import { Helmet } from 'react-helmet';
import AppDawnloadPopup from "../../dialogs/app-dawnload-popup/app-popup"


const LandingHome = () => {
  const [showPopup, setShowPopup] = useState(true);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="landing-home-container">
      <JoinOptions />
      <Navbar />
      <div className="landing-page">
        <Helmet>
          <title>Fast & Reliable Food Delivery App in Pakistan | ForunPoint</title>
          <meta
            name="description"
            content="Get  best food delivery app ForunPoint in Pakistan. Enjoy fast, reliable service and various delicious meals delivered right to your doorstep. Download now!"
          />
        </Helmet>
        <div className="image-sec"></div>
        <div className="landing-content">
          <img src={forunpointText} alt="Forunpoint Text" className="forunpoint-text" />
          <h1 className="landing-heading">Food and Gift Delivery!</h1>
          <p className="landing-description">Chutki Bajate Forun Hazir!</p>
        </div>
        <img src={salad} className="salad-img" alt="Salad" />
        <img src={landingImg} className="dish-img" alt="Dish" />
        <img src={rider} className="rider-img" alt="Rider" />
        <div className="row landingcard2">
          <div className="col-md-6 col-lg-5">
            <div className="landing-card">
              <div className="card-body">
                <div className="input-group md-form form-sm form-2 pl-0">
                  <Autocomplete />
                  <div className="input-group-append"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CookieConsent />
      {showPopup && <AppDawnloadPopup onClose={handleClosePopup} />}
    </div>
  );
};

export default LandingHome;
