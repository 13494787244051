import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import SignupComponent from '../signup/signup';
import './login.css';
import { login, forgotPassword, changePassword } from '../../../shared/apis';
import { useNavigate } from 'react-router-dom';
import loginicon from '../../../assets/images/login-ico.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginComponent = ({ open, onCloseModal }) => {
  const [loginActive, setLoginActive] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [signupMode, setSignupMode] = useState('email');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignupClick = () => {
    setLoginActive(false);
    setOpen2(true);
    setSignupMode('email');
    onCloseModal();
  };

  const onCloseModal2 = () => {
    setOpen2(false);
    setLoginActive(false);
    setSignupMode('email');
  };

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("FpToken");
    navigate('/');
  };

  const signIn = async () => {
    if (!email || !password) {
      setError('Please provide both email and password.');
      setTimeout(() => setError(''), 5000);
      return;
    }

    setForgotPasswordLoading(true);
    try {
      const response = await login({ email, password });
      const token = response.data.token;
      const fpToken = response.data.FpUser.token;

      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("FpToken", JSON.stringify(fpToken));
      // navigate('/');

      onCloseModal();
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Invalid email or password!');
      } else if (error.response && error.response.status === 401) {
        logoutUser();
      } else {
        setError('An error occurred. Please try again.');
      }
      setTimeout(() => setError(''), 3000);
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  const handleForgotPasswordClick = async () => {
    if (!email) {
      setError('Please enter your email.');
      setTimeout(() => {
        setError('');
      }, 2000);
      return;
    }

    setForgotPasswordLoading(true);
    try {
      await forgotPassword({ email });
      setError('');
      setSuccess('Verification code sent to your email.');

      setTimeout(() => {
        setSuccess('');
      }, 2000);
    } catch (error) {
      setError('Failed to send verification code. Please try again.');
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  const handleResetPasswordClick = async () => {
    if (!verificationCode || !newPassword) {
      setError('Please provide the verification code and a new password.');
      setTimeout(() => {
        setError('');
      }, 2000);
      return;
    }

    setResetPasswordLoading(true);
    try {
      const response = await changePassword({
        email,
        resetPasswordCode: verificationCode,
        password: newPassword
      });

      if (response.status.code === 200) {
        setSuccess('Password reset successfully. You can now log in.');
        setForgotPasswordMode(false);
        setTimeout(() => {
          setSuccess('');
        }, 2000);
      } else {
        setError('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setError('Failed to reset password. Incorrect Code.');
      setTimeout(() => {
        setError('');
      }, 2000);
    } finally {
      setResetPasswordLoading(false);
    }
  };

  return (
    <>
      <div className="login-modal">
        <Modal open={open} onClose={onCloseModal} classNames={{ modal: 'mymodal' }} center styles={{
          modal: {
            width: '80%',
            maxWidth: '400px',
            height: 'auto',
            borderRadius: '2px'
          }
        }}>
          <div className="login-popup">
            <img src={loginicon} className='login-icon' alt="Login" />
            <br />
            <h2 className="login-title">Login to your account</h2>
            {!forgotPasswordMode ? (
              <>
                <label htmlFor="Email" className='field-labels'>Email</label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email' className='login-fields' required />



                <label htmlFor="Password" className='field-labels password-labl' id='label-2'>Password <span className="forgot-password" onClick={() => setForgotPasswordMode(true)}>Forgot Password?</span></label>
                  <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </span>
                <input type={passwordVisible ? 'text' : 'password'} value={password} placeholder='Enter your password' onChange={(e) => setPassword(e.target.value)} className='login-fields' required />
                  <button
                    onMouseDown={signIn}
                    className='login-btn-modal dialog-btn'
                    disabled={forgotPasswordLoading}
                    style={{ backgroundColor: forgotPasswordLoading ? '#d3d3d3' : '', cursor: forgotPasswordLoading ? 'not-allowed' : '' }}
                  >
                    {forgotPasswordLoading ? 'Logging In...' : 'Login Now'}
                  </button>
                </>
                ) : (
                <>
                  <label htmlFor="Email" className='field-labels'>Enter your email</label>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' className='login-fields' required />
                  <button
                    onClick={handleForgotPasswordClick}
                    className='login-btn-modal dialog-btn forgot-btn'
                    disabled={forgotPasswordLoading}
                    style={{ backgroundColor: forgotPasswordLoading ? '#d3d3d3' : '', cursor: forgotPasswordLoading ? 'not-allowed' : '' }}
                  >
                    {forgotPasswordLoading ? 'Sending Code...' : 'Send Verification Code'}
                  </button>
                  <br />
                  <br />
                  <br />


                  <label htmlFor="Code" className='field-labels'>Enter the verification code sent to your email</label>
                  <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} placeholder='Verification code' className='login-fields' required />
                  <label htmlFor="NewPassword" className='field-labels'>Enter your new password</label>
                  <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='New password' className='login-fields' required />

                  <button
                    onClick={handleResetPasswordClick}
                    className='login-btn-modal dialog-btn'
                    disabled={resetPasswordLoading}
                    style={{ backgroundColor: resetPasswordLoading ? '#d3d3d3' : '', cursor: resetPasswordLoading ? 'not-allowed' : '' }}
                  >
                    {resetPasswordLoading ? 'Resetting Password...' : 'Reset Password'}
                  </button>
                </>
            )}
                <br />
                <div className='d-flex signup-btn-sec'>
                  <span className='signup-text'>
                    Don't have an account ?
                  </span>
                  <button className='signup-btn-modal signup-btn2 dialog-btn' onMouseDown={handleSignupClick}>Sign Up</button>
                </div>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
              </div>
        </Modal>
      </div>
      <div className="modal">
        <SignupComponent open={open2} onCloseModal={onCloseModal2} signupMode={signupMode} setSignupMode={setSignupMode} />
      </div>
    </>
  );
};

export default LoginComponent;
