// import React from "react";
// import "./heroBanner.css";

// const HeroBanner = ({ city, headline, subtext }) => {
//   return (
//     <div className="hero-banner">
//       <div className="hero-banner-content">
//         <h1 className="hero-headline">{headline}</h1>
//         {subtext && <p className="hero-subtext">{subtext}</p>}
//         {city && <div className="hero-location"><span>{city}</span></div>}
//       </div>
//     </div>
//   );
// };

// export default HeroBanner;
import React from "react";
import "./heroBanner.css";

const HeroBanner = ({ breadcrumb }) => {
  return (
    <div className="hero-banner">
      <div className="hero-breadcrumb">{breadcrumb}</div>
    </div>
  );
};

export default HeroBanner;
