import './order-details.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/footer';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import 'react-responsive-modal/styles.css';
import CartComponent from '../home/cart/cart';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { getMe, createPaymentMethod, attachPaymentMethodToCustomer, getpaymentMethod, updateUser, detachPaymentMethod, getCouponDetails, getSticker } from '../../shared/apis';
import Spinner from 'react-bootstrap/Spinner';
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import TextInput from 'react-autocomplete-input';
// import 'react-autocomplete-input/dist/bundle.css';
// import { Typeahead } from 'react-bootstrap-typeahead';
// import { Form } from 'react-bootstrap';
import Autocompletend from '../home/Autocomplete-nd/Autocompletend';
import axios from 'axios';
import camera from '../../assets/images/camera.png'
import superman from '../../assets/images/superman.png'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Stripe from '../stripe-payment/stripe'
import Record from './record'
// const stripePromise = loadStripe('pk_live_51JBfwJBxfFf0pS20Ux3pgwWwq2uwzcoJhnRVfq9Uqwna1XWKwks9q26WzB67mCUxW2vDF0bbr1rxe5yswpSy88FY00Y6Wyb0kZ');
const stripePromise = loadStripe('pk_test_51JBfwJBxfFf0pS206zAE8etSsCFQu4362b0JOWSxdHvZ9X0CpBtMgLvhgZ2oTL9OLC8ZDNbRyDpBLggkClKw4gh3006quV5lVz');

function Checkout() {
  const location = useLocation();
  // const { convertedSubtotal } = location.state || {};
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [open, setOpen] = useState(false);
  // const [inputValue, setInputValue] = useState('');
  // const [buttonColor, setButtonColor] = useState('rgb(51, 51, 51)');
  const [cartItems, setCartItems] = useState();
  const [giftItems, setGiftItems] = useState();
  // const [selectedLocation, setSelectedLocation] = useState(null);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [addressInput, setAddressInput] = useState('');
  // const [activeButton, setActiveButton] = useState(null);
  const [cartItem, setCartItem] = useState();
  const [giftItem, setGiftItem] = useState();
  // const [doneButtonClick, setDoneButtonClick] = useState(false);
  const [user, setUser] = useState(null);
  // const [accordionList, setAccordionList] = useState([]);
  // const [addressDetails, setAddressDetails] = useState({
  //   houseNumber: '',
  //   noteToRider: ''
  // });
  // const [additionalAccordions, setAdditionalAccordions] = useState([]);
  // const [userEnteredAccordions, setUserEnteredAccordions] = useState([]);
  // const [checkoutData, setCheckoutData] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [date, setDate] = useState('');
  const [cvc, setCvc] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [pmId, setPmId] = useState([]);

  const [restaurant, setRestaurant] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelivery, setIsDelivery] = useState(true);
  const [selectedPM, setSelectedPM] = useState('Cod');
  // const [paymentStatus, setPaymentStatus] = useState('');
  // const [amounted, setAmounted] = useState('amount');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const storedCountry = localStorage.getItem('country');
  const [riderNote, setRiderNote] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [userDeliveryAddress, setUserDeliveryAddress] = useState('');

  const [formCompleted, setFormCompleted] = useState(true);

  const [isRecorded, setIsRecorded] = useState(false);
  // const [stream, setStream] = useState(null);
  // const [videoURL, setVideoURL] = useState(null);
  // const [videoBlob, setVideoBlob] = useState(null);
  // const mediaRecorderRef = useRef(null);
  // const chunks = useRef([]);
  // const newWindowRef = useRef(null);
  // const videoElementRef = useRef(null);
  const [loadingId, setLoadingId] = useState(null);
  const [sticker, setSticker] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [appoint, setAppoint] = useState('');
  const [showTextArea, setShowTextArea] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedStickerId, setSelectedStickerId] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [cartForm, setCartForm] = useState();
  const [error, setError] = useState('');
  const savedSubtotal = localStorage.getItem('mulSubtotal');

  // const [convertedSubtotal, setConvertedSubtotal] = useState(null);


  const handleVideoUpload = (url) => {
    setVideoUrl(url);
    // console.log('Video URL received from child:', url);
  };
  const init = async () => {
    const Sticky = await getSticker();
    setSticker(Sticky.data.Stickers)
    // console.log('init', Sticky.data.Stickers)
    let giftItems = localStorage.getItem('giftItems');

    if (giftItems && giftItems.length > 0) {
      setFormCompleted(false);

    }
  };

  const handleStickerClick = (stickerId) => {
    setSelectedStickerId(stickerId); // Mark the sticker as selected

    // Update formData if needed here or when submitting the form
  };

  const handleNextClick = () => {
    const formData = {
      name,
      email,
      phone,
      appoint,
      message: showTextArea ? message : '',
      stickerId: selectedStickerId,
      video: videoUrl,
    };

    // Add the form details to the cart
    sendFormDataToCart(formData);
    setFormCompleted(true);
  };
  const sendFormDataToCart = (formData) => {
    // console.log('Sending form data to cart:', formData);
    // Add your logic to send this data to the cart system
    // This could be an API call or a state update
    // For example:
    setCartForm(formData); // This will keep the form data separate from cart items
    // console.log('going to send', cartForm)
  };


  const handleCheckboxChange = () => {
    setShowTextArea(!showTextArea);
  };

  const handleNoteChange = (event) => {
    setRiderNote(event.target.value);
  };
  const handleAdressChange = (event) => {
    setReceiverAddress(event.target.value);
    localStorage.setItem('userDeliveryAddress', receiverAddress);

    if (event.target.value !== '') {
      setError(''); // Clear error when the user types
    }
  };
  const handleCartError = (errorMessage) => {
    setError(errorMessage); // Set the error in the parent
  };

  const handlePaymentMethodChange = (event) => {
    const { value } = event.target;
    // console.log('value to set',value)
    setSelectedPaymentMethod(value);


    localStorage.setItem('selectedPaymentMethod', value);
  };

  const handlePaymentMethodChange2 = (event) => {
    const { value } = event.target;

    setSelectedPaymentMethod(value);

    localStorage.setItem('selectedPaymentMethod2', value);
  };

  const handlePaymentMethodChange3 = (event) => {
    const { value } = event.target;
    setSelectedPM(event.target.value);
    // console.log(selectedPM)
    setSelectedPaymentMethod(value);
    localStorage.setItem('paymentBy', event.target.value);


    localStorage.setItem('selectedPaymentMethod3', value);
  };

  const handlePlaceOrder = () => {
    if (isFormValid()) {
      // Proceed with placing the order
    } else {
      alert('Please enter your delivery address and select a payment method.');
    }
  };

  const isFormValid = () => {
    return deliveryAddress !== '' && selectedPaymentMethod !== '';
  };

  const handleOptionChange = (event) => {
    // localStorage.setItem('selectedPaymentMethod3');
    setSelectedPM(event.target.value);
    // console.log(selectedPM)
    localStorage.setItem('paymentBy', event.target.value);

  };
  useEffect(() => {
    renderCardList();

    fetchData();
    init();
    const storedObjectString = localStorage.getItem('cartItems');
    if (storedObjectString) {
      const retrievedObject = JSON.parse(storedObjectString)
      setCartItem(retrievedObject);
      setRestaurant(retrievedObject[0]?.cartItems?.dish?.user);
      if (retrievedObject[0]?.orderType === "DELIVERY") {
        setIsDelivery(true);

      }
      else if (retrievedObject[0]?.orderType === "PICKUP") {
        setIsDelivery(false);

      }

    }

    const storedObjectString2 = localStorage.getItem('giftItems');
    if (storedObjectString2) {
      const retrievedObject2 = JSON.parse(storedObjectString2)
      setGiftItem(retrievedObject2);
      setRestaurant(retrievedObject2[0]?.giftItems?.dish?.user);
      if (retrievedObject2[0]?.orderType === "DELIVERY") {
        setIsDelivery(true);

      }
      else if (retrievedObject2[0]?.orderType === "PICKUP") {
        setIsDelivery(false);

      }

    }
  }, []);

  // const fetchData = async () => {
  //   setIsLoading(true); // Set isLoading to true before fetching data
  //   try {
  //     const userProfile = await getMe();
  //     setUser(userProfile.data.user);
  //     setPmId(userProfile.data.user.paymentMethods)
  //     console.log('ceee',user.paymentMethods)
  //     const pm = await Promise.all(
  //       userProfile.data.user.paymentMethods.map(async (paymentMethod) => {
  //         const paymentMethodData = await getpaymentMethod(paymentMethod);
  //         return paymentMethodData;
  //       })
  //     );

  //     setPaymentMethods(pm);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setIsLoading(false); // Set isLoading to false after data fetching is complete
  //   }
  // };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const userProfile = await getMe();
      setUser(userProfile.data.user);

      // Fetch detailed payment method data for each ID in userProfile.data.user.paymentMethods
      const pm = await Promise.all(
        userProfile.data.user.paymentMethods.map(async (paymentMethodId) => {
          const paymentMethodData = await getpaymentMethod(paymentMethodId);
          return paymentMethodData;
        })
      );

      setPaymentMethods(pm);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const [formData, setFormData] = useState({
    pp_Version: '1.1',
    pp_TxnType: '',
    pp_TxnType2: 'MPAY',
    pp_Language: 'EN',
    // pp_MerchantID: '98790685',
    // test
    // pp_MerchantID: 'MC97552',
    pp_MerchantID: 'MC90551',

    // pp_Password: '3399w2cv12',
    // test
    // pp_Password: '4441ggs1zh',
    pp_Password: 'u868y8vt23',



    pp_BankID: '',
    pp_ProductID: '',
    pp_TxnRefNo: '',
    pp_Amount: savedSubtotal,
    pp_TxnCurrency: 'PKR',
    pp_TxnDateTime: '',
    pp_BillReference: '',
    pp_Description: '',
    pp_TxnExpiryDateTime: '',
    pp_ReturnURL: 'https://forunpoint-pk-dev-000da96aacf1.herokuapp.com/api/v1/orders/jazzcash-payment',
    pp_SecureHash: '',
    ppmpf_1: '',
    ppmpf_2: '',
    ppmpf_3: '',
    ppmpf_4: '',
    ppmpf_5: '',
  });
  // live
  // const integritySalt = 'dz13zv0yy1';
  // test
  // const integritySalt = 'awt2ut04c9';
  const integritySalt = '1bd5yy0359';



  useEffect(() => {
    const initializeValues = () => {
      const billRef = `billRef${Math.floor(Math.random() * 1000)}`;
      const description = `Test description of product - ${Math.floor(Math.random() * 1000)}`;
      const transID = `T${Math.floor(Math.random() * 1000000000000)}`;

      setFormData(prevData => ({
        ...prevData,
        pp_BillReference: billRef,
        pp_Description: description,
        pp_TxnRefNo: transID,
        pp_TxnDateTime: moment().format('YYYYMMDDHHmmss'),
        pp_TxnExpiryDateTime: moment().add(1, 'days').format('YYYYMMDDHHmmss'),
      }));
    };

    initializeValues();
  }, []);

  // const calculateHash = () => {
  //   const {
  //     pp_Amount, pp_BankID, pp_BillReference, pp_Description, pp_Language, pp_MerchantID,
  //     pp_Password, pp_ProductID, pp_ReturnURL, pp_SubMerchantID, pp_TxnCurrency, pp_TxnDateTime,
  //     pp_TxnExpiryDateTime, pp_TxnRefNo, pp_TxnType, pp_Version, ppmpf_1, ppmpf_2, ppmpf_3,
  //     ppmpf_4, ppmpf_5
  //   } = formData;

  //   let hashString = `${integritySalt}&${pp_Amount}&${pp_BankID}&${pp_BillReference}&${pp_Description}&${pp_Language}&${pp_MerchantID}&${pp_Password}&${pp_ProductID}&${pp_ReturnURL}&${pp_SubMerchantID}&${pp_TxnCurrency}&${pp_TxnDateTime}&${pp_TxnExpiryDateTime}&${pp_TxnRefNo}&${pp_TxnType}&${pp_Version}&${ppmpf_1}&${ppmpf_2}&${ppmpf_3}&${ppmpf_4}&${ppmpf_5}`;
  //   hashString = hashString.slice(0, -1);

  //   const hash = CryptoJS.HmacSHA256(hashString, integritySalt).toString();

  //   setFormData(prevData => ({
  //     ...prevData,
  //     pp_SecureHash: hash,
  //   }));
  //   console.log(formData)
  // };

  const submitForm = () => {
    // calculateHash();
    const {
      pp_Amount, pp_BankID, pp_BillReference, pp_Description, pp_Language, pp_MerchantID,
      pp_Password, pp_ProductID, pp_ReturnURL, pp_SubMerchantID, pp_TxnCurrency, pp_TxnDateTime,
      pp_TxnExpiryDateTime, pp_TxnRefNo, pp_TxnType, pp_Version, ppmpf_1, ppmpf_2, ppmpf_3,
      ppmpf_4, ppmpf_5
    } = formData;

    let hashString = `${integritySalt}&${pp_Amount}&${pp_BankID}&${pp_BillReference}&${pp_Description}&${pp_Language}&${pp_MerchantID}&${pp_Password}&${pp_ProductID}&${pp_ReturnURL}&${pp_SubMerchantID}&${pp_TxnCurrency}&${pp_TxnDateTime}&${pp_TxnExpiryDateTime}&${pp_TxnRefNo}&${pp_TxnType}&${pp_Version}&${ppmpf_1}&${ppmpf_2}&${ppmpf_3}&${ppmpf_4}&${ppmpf_5}`;
    hashString = hashString.slice(0, -1);

    const hash = CryptoJS.HmacSHA256(hashString, integritySalt).toString();

    setFormData(prevData => ({
      ...prevData,
      pp_SecureHash: hash,
    }));
    // console.log(formData)
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://sandbox.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform';

    Object.keys(formData).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = formData[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  // const removePayment = async (pmId) => {
  //   try {
  //     setSubmitted(true);
  //     const updatedPaymentMethods = paymentMethods.filter((pm) => pm.paymentMethod.id !== pmId);
  //     setPaymentMethods(updatedPaymentMethods);
  //     await detachPaymentMethod(pmId);
  //     setOpen3(false);
  //   } catch (error) {
  //     console.error('Error removing payment method:', error);
  //   }
  // };

  const removePayment = async (pmId) => {
    try {
      setLoadingId(pmId); // Set the loading state for the clicked button
      setSubmitted(true);

      // Remove the selected payment method ID from the userProfile
      const updatedPaymentMethodIds = user.paymentMethods.filter((id) => id !== pmId);
      setPmId(updatedPaymentMethodIds);

      // Update the user profile on the server
      await updateUser(user._id, { paymentMethods: updatedPaymentMethodIds });

      // Remove the payment method from Stripe (if necessary)
      await detachPaymentMethod(pmId);

      // Refresh the payment methods in the UI
      setPaymentMethods(prevMethods => prevMethods.filter(pm => pm.paymentMethod.id !== pmId));
      setOpen3(false);
    } catch (error) {
      console.error('Error removing payment method:', error);
    } finally {
      setLoadingId(null); // Reset the loading state
    }
  };



  const selectPaymentMethod = async (pm_Id) => {
    setPaymentMethodId(pm_Id);
    setSelectedPM('Card');
    localStorage.setItem('paymentBy', 'Card');
    await updateUser(user._id, { stripeCustomerCard: pm_Id })

  }

  // const renderCardList = () => {
  //   if (!paymentMethods) {
  //     return null;
  //   }
  //   return paymentMethods.map((pm, index) => (
  //     <div className="final-payment-method" key={index}>
  //       <img src="./images/genral-card.png" alt="" className='final-genral-card' />
  //       <h3 className='final-card-number2'>************{pm?.paymentMethod.card?.last4}</h3>
  //       <input
  //         type="radio"
  //         name="selectedPaymentMethod"
  //         value={pm.paymentMethod.id}
  //         className='payment-radio-btn'
  //         onClick={() => { selectPaymentMethod(pm.paymentMethod.id) }}
  //         onChange={handlePaymentMethodChange}
  //       // checked={card.isChecked || false}
  //       />
  //     </div>

  //   ));
  //   // });
  // };
  // const renderCardList = () => {
  //   if (!paymentMethods || paymentMethods.length === 0) {
  //     return null; // Return null if there are no payment methods to render
  //   }

  //   return paymentMethods.map((pm, index) => (
  //     <div className="final-payment-method" key={index}>
  //       <img src="./images/genral-card.png" alt="" className='final-genral-card' />
  //       <h3 className='final-card-number2'>************{pm?.paymentMethod.card?.last4}</h3>
  //       <input
  //         type="radio"
  //         name="selectedPaymentMethod"
  //         value={pm.paymentMethod.id}
  //         className='payment-radio-btn'
  //         onClick={() => { selectPaymentMethod(pm.paymentMethod.id) }}
  //         onChange={handlePaymentMethodChange}
  //       />
  //     </div>
  //   ));
  // };
  const renderCardList = () => {
    if (!paymentMethods || paymentMethods.length === 0) {
      return null; // Return null if there are no payment methods to render
    }

    return paymentMethods.map((pm, index) => (
      <div className="final-payment-method" key={index}>
        <img src="./images/genral-card.png" alt="" className='final-genral-card' />
        <h3 className='final-card-number2'>************{pm?.paymentMethod.card?.last4}</h3>
        <input
          type="radio"
          name="selectedPaymentMethod"
          value={pm.paymentMethod.id}
          className='payment-radio-btn'
          onClick={() => { selectPaymentMethod(pm.paymentMethod.id) }}
          onChange={handlePaymentMethodChange}
        />
        <button
          className="remove-button"
          onClick={() => removePayment(pm.paymentMethod.id)}
          disabled={loadingId === pm.paymentMethod.id} // Disable the button during loading
        >
          {loadingId === pm.paymentMethod.id ? (
            <div className="loading-spinner"></div>
          ) : (
            'Remove'
          )}
        </button>
      </div>
    ));
  };




  const addToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);

    if (existingItem) {
      setCartItems((prevCartItems) =>
        prevCartItems.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCartItems((prevCartItems) => [...prevCartItems, item]);
    }
    setTimeout(() => {
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, 700);
  };

  const giftToCart = (item) => {
    const existingItem = giftItems.find((giftItem) => giftItem.id === item.id);

    if (existingItem) {
      setGiftItems((prevGiftItems) =>
        prevGiftItems.map((giftItem) =>
          giftItem.id === item.id
            ? { ...giftItem, quantity: giftItem.quantity + 1 }
            : giftItem
        )
      );
    } else {
      setGiftItems((prevGiftItems) => [...prevGiftItems, item]);
    }
    setTimeout(() => {
      localStorage.setItem('giftItems', JSON.stringify(giftItems));
    }, 700);
  };

  const checkout = async () => {
    try {
      setIsButtonClicked(true);
      let card = {
        number: cardNumber,
        exp_month: date.split('-')[1],
        exp_year: date.split('-')[0],
        cvc: cvc
      };
      if (user.stripeCustomerId) {
        let pm = await createPaymentMethod(card);
        let paymentMethods = user.paymentMethods
        paymentMethods.push(pm.paymentMethod.id)
        setPaymentMethodId(pm.paymentMethod.id)
        await attachPaymentMethodToCustomer({ paymentMethodId: pm.paymentMethod.id, customerId: user.stripeCustomerId });
        await updateUser(user._id, { paymentMethods: paymentMethods })

        fetchData();
        renderCardList();
        setIsButtonClicked(false);
      }
    } catch (error) {
      console.error('Error adding payment method:', error);
      setIsButtonClicked(false);
    }

  };

  return (
    <>
      <Navbar onCartError={handleCartError} cartForm={cartForm} receiverAddress={receiverAddress} />
      <div className="main-order-body">
        <div className="order-section">
          <div className="order-sec-details">
            <h2 className='order-sec-title'>Order From:</h2>
            <p className='order-sec-desc'>{restaurant?.restaurantName}</p>
          </div>

          {!formCompleted ? (
            <div className='form-sec-orderdetails'>
              <div
                style={{
                  minWidth: showTextArea ? '130%' : '130%',
                  margin: '0 auto',
                  transition: 'width 0.3s ease-in-out',
                }}
              >
                <div className="form-container" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                  <img src={superman} className='superman' style={{ width: showTextArea ? '22%' : '22%' }} />
                  <div style={{ width: showTextArea ? '100%' : '100%' }}>
                    <h2 className="details-form-title">Delivery Details</h2>
                    <form style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <div style={{ width: showTextArea ? '48%' : '100%' }}>
                        <div className="form-group">
                          <label>Name</label>
                          <input type="text" name="name" className="details-form-input" required value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <input type="email" name="email" className="details-form-input" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input type="text" name="phone" className="details-form-input" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>Date and Approximate Time</label>
                          <input type="text" name="appoint" className="details-form-input" required value={appoint} onChange={(e) => setAppoint(e.target.value)} />
                        </div>
                        <div className="form-group videogreetingsec">
                          <input
                            type="checkbox"
                            name="info"
                            checked={showTextArea}
                            onChange={handleCheckboxChange}
                            className="details-form-input checkbox-Input"
                          />
                          <span className="video-greeting-check">Video and Text Greetings</span>
                        </div>
                        <p className="details-form-desc">
                          Personalizing the message and choosing a heartfelt video will deeply touch your loved ones.
                        </p>
                      </div>
                      {showTextArea && (
                        <div style={{ display: 'block', width: '50%', marginTop: 27 }}>
                          <div className='form-textarea'>
                            <textarea
                              className='maintextarea'
                              placeholder="Enter your message here..."
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </div>
                          <div className='form-textarea'>
                            <div
                              className='maintextarea'
                              style={{
                                cursor: 'pointer'
                              }}

                            >
                              {!isRecorded ? (
                                <div>
                                  {/* <img src={camera} className='camera-img' /> */}
                                  <Record onVideoUpload={handleVideoUpload} />
                                  {/* <p className="camera-desc">Record A Video Message</p> */}
                                </div>
                              ) : (
                                <div style={{ textAlign: 'center', marginTop: '22.7%' }}>
                                  <p style={{ fontSize: 12 }}>Video has been recorded</p>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='sticker-container form-textarea'>
                            <div>
                              {sticker.map((sticker, index) => (
                                // <img key={index} src={sticker.picture} className='sticker-img' />
                                <img
                                  key={index}
                                  src={sticker.picture}
                                  alt={sticker.name}
                                  className={`sticker-img ${selectedStickerId === sticker._id ? 'selected-sticker' : ''}`}
                                  onClick={() => handleStickerClick(sticker._id)} // Click handler for selecting a sticker
                                  style={{ cursor: 'pointer', border: selectedStickerId === sticker._id ? '2px solid red' : 'none' }} // Optional: Add border when selected
                                />
                              ))}
                            </div>
                          </div>

                          {/* {!isRecorded && stream && (
                                <div style={{ textAlign: 'center' }}>
                                  <button onClick={handleStopRecording} className='stoprecording-btn'>Stop Recording</button>
                                </div>
                              )} */}
                          <div>
                            {/* <img src={foodContainer} className='foodContainer' /> */}
                          </div>
                        </div>
                      )}
                    </form>
                    <button type="button" onClick={handleNextClick} className="next-button">
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <div className="cart-component cart-sec-order" style={{ marginLeft: showTextArea ? '-50%' : '0' }} >
                <CartComponent itemsInCart={cartItem} giftInCart={giftItem} pmId={paymentMethodId} paymentBy={selectedPM} addToCart={addToCart} giftToCart={giftToCart} cartForm={cartForm} receiverAddress={receiverAddress} riderNote={riderNote} onCartError={handleCartError} />
              </div>
            </div>
          ) : (
            <div className="order-details">
              <div className="order-content">
                {/* <Autocompletend/> */}
                <div style={{ margin: '20px 0' }}>
                  <label htmlFor="address" className='order-sec-address'>
                    We're missing your street Address and House No:
                  </label>
                  <textarea
                    id="address"
                    placeholder="Enter your Street Address and House No here..."
                    style={{
                      width: '100%',
                      height: '40px',
                      padding: '8px 10px 10px 20px',
                      borderRadius: '5px',
                      fontSize: '12px',
                      resize: 'none',
                      backgroundColor: '#FFF',
                      borderRadius: '10px',
                      marginBottom: '-7px'
                    }}
                    onChange={handleAdressChange} // This updates the state whenever the user types
                  />
                  <style>
                    {`
    #address::placeholder {
      font-size: 10px; /* Adjust the size as needed */
      color: #888; /* Optional: Change the color if desired */
    }
  `}
                  </style>
                  {error && <p style={{ color: 'red', fontSize: '12px' }}>{error}</p>}
                </div>
                {/* 
                {isDelivery && (<div>
                  <p className='order-sec-address'>Enter the Delivery Address Here.</p>
                  <Autocompletend />
                </div>
                )} */}
                <div style={{ margin: '20px 0' }}>
                  <label htmlFor="note" className='order-sec-address'>
                    Add a Note for the Rider:
                  </label>
                  <textarea
                    id="note"
                    placeholder="Write your note for rider here..."
                    style={{
                      width: '100%',
                      height: '70px',
                      padding: '8px 10px 10px 20px',
                      borderRadius: '5px',
                      fontSize: '12px',
                      resize: 'none',
                      backgroundColor: '#FFF',
                      borderRadius: '10px',
                      marginBottom: '-7px'
                    }}
                    onChange={handleNoteChange} // This updates the state whenever the user types
                  />
                  <style>
                    {`
    #note::placeholder {
      font-size: 10px; /* Adjust the size as needed */
      color: #888; /* Optional: Change the color if desired */
    }
  `}
                  </style>
                </div>

                <div className="order-cards">
                  <div className="payment-method-section">
                    <div className="payment-method-2">
                      <img src="./images/payment_wallet.png" className='payment-wallet-2' alt="" />
                      <p className='payment-method-text-2'>Payment Methods:</p>
                    </div>
                    {isLoading ? (
                      <div className="slider-content d-flex justify-content-center align-items-center">
                        <div className='m-4' style={{ textAlign: 'center' }}>
                          <Spinner animation="border" variant="danger" />
                        </div>
                      </div>
                    ) : (
                      renderCardList()
                    )}
                  </div>
                </div>
                <div className="order-cards">
                  <div className="card-body payment-content-body">
                    <div className="card-top-content">
                      <h2 className="order-card-title">Payment Details</h2>
                      {/* <p className='change-btn'>Change</p> */}
                    </div>
                    <div className="payment-container">
                      <div id="accordion">
                        {/* <div className='paypal-containers'>
                        <input type="radio" name="accordion-group" id="option-1" className='input' />
                        <label for="option-1" className='label'><p>PayPal</p><img src="/images/image 19.png" alt="" className='paypal' /></label>
                        <article className='paypal-section'>
                          <p className='paypal-title'>Use Paypal as payment method for your order</p>
                          <p className='paypal-desc'>Enjoy the convenience of paying the Point Rider directly from the website</p>
                        </article>
                      </div> */}

                        {storedCountry === 'PK' && (<div className='paypal-containers'>
                          <input
                            type="radio"
                            name="accordion-group"
                            id="option-1"
                            className='input'
                            value="Cod" // Set the value you want to return
                            onChange={handlePaymentMethodChange3}
                          />
                          <label for="option-1" className='label'><p>Cash on Delivery</p><img src="/images/cod.jpg" alt="" className='paypal' /></label>
                          {selectedPaymentMethod === 'Cod' && (
                            <p style={{ fontSize: '12px', color: '#d4110f', textAlign: 'left' }}>
                              Note: COD selected as payment method, Please click on cart to procced.
                            </p>
                          )}
                        </div>

                        )}
                        {/* jazzcash working one */}
                        {/* <div className='paypal-containers' onClick={submitForm}>
                          <input
                            type="radio"
                            name="accordion-group"
                            id="option-3"
                            className='input'
                            value="jazzcash" // Set the value you want to return
                            onChange={handleOptionChange} // Call the function when the input changes
                          />
                          <label for="option-3" className='label'><p>Credit or Debit Card</p><img src="/images/image 20.png" alt="" className='paypal cred' /></label>
                        </div> */}


                        {storedCountry === 'PK' && (
                          <div
                            className='paypal-containers'
                            onClick={null} // Disable the onClick event
                            style={{
                              opacity: 0.5, // Grey out the container
                              pointerEvents: 'none', // Disable interaction
                            }}
                          >
                            <input
                              type="radio"
                              name="accordion-group"
                              id="option-3"
                              className='input'
                              value="jazzcash" // Set the value you want to return
                              onChange={handleOptionChange} // Call the function when the input changes
                              disabled={true} // Disable the radio button
                            />
                            <label htmlFor="option-3" className='label'>
                              <p>Under Maintenance Coming Soon...</p>
                              <img src="/images/image 20.png" alt="" className='paypal cred' />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="cart-component">
                <CartComponent itemsInCart={cartItem} giftInCart={giftItem} pmId={paymentMethodId} paymentBy={selectedPM} addToCart={addToCart} giftToCart={giftToCart} cartForm={cartForm} receiverAddress={receiverAddress} riderNote={riderNote} onCartError={handleCartError} />
              </div>
            </div >
          )}
        </div >
        <Footer />
      </div >
    </>
  );
}

export default Checkout;