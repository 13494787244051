import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/footer";
import "./terms.css";
import { Helmet } from 'react-helmet';

const Terms = () => {
  return (
    <div>
      <Helmet>
        <title>Terms and Conditions | ForunPoint's User Agreement</title>
      </Helmet>
      <Navbar />
      <div>
        <img className="privacy-img" src="./images/termsimg.png" alt="Terms" />
      </div>
      <div className="privacy mt-5 mb-5">
        <div className="privacy-title">Terms and Conditions</div>
        <div className="privacy-title-second mt-4">Welcome to Forun Point!</div>
        <div className="privacy-data mt-2">
        </div>
        {/* <div className="privacy-data mt-2">
          • Forun Point App (Available on Google Play and App store)
        </div> */}

        <ul className="privacy-data" >
          <li>
            <b>forunpoint Platform (Available on Google Play and App store)</b>
          </li>
        </ul>

        <div className="privacy-data mt-2">
          By using the platform and registering as a user, you agree to be bound by these terms and conditions and our privacy policy. In these terms and conditions, the words "platform" and “platform” refer to the Forun Point platform, "we", "us", "our" and “Forun Point” refer to Forun Point and "you" and “user" refer to you, the user.
        </div>

        <div className="privacy-data mt-2">
          The following terms and conditions apply to your use of the platform. This includes mobile and tablet versions, as well as any other version of Forun Point accessible via desktop, mobile, tablet, social media or other devices.
        </div>

        <div className="privacy-data mt-2">
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING, POSTING INFORMATION ON, OR OBTAINING ANY SERVICES OR PRODUCTS FROM THE PLATFORM.
        </div>

        <div className="privacy-title-second mt-4">1. ACCEPTANCE OF TERMS</div>

        <div className="privacy-data mt-3">
          This agreement sets forth legally binding terms for your use of Forun Point. By registering and using the platform, you agree to be bound by this agreement. If you do not accept the terms of this agreement, you should not use the platform and discontinue use of the service immediately. We may modify this agreement from time to time, and such modification shall be effective upon its posting on the platform. You agree to be bound by any modification to this terms and conditions when you use Forun Point after any such modification is posted; it is therefore important that you review this agreement regularly. You represent and warrant that all registration information you submit is accurate and truthful; and that your use of the platform does not violate any applicable law or regulation. Forun Point may, in its sole discretion, refuse to offer the services to any user and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the service and the platform is revoked in such jurisdictions. The platform may only be used in compliance with these terms and all applicable local, state, national and international laws, rules and regulations. By registering and using the platform, you represent and warrant that you have the full right, power and authority to enter into this agreement and to fully perform all of your obligations hereunder. You further represent and warrant that you are under no legal disability or contractual restriction that prevents you from entering into this agreement.
        </div>

        <div className="privacy-title-second mt-4">2. Restaurant/Chef</div>
        <div className="privacy-title-second mt-4">2.1. Registration</div>

        <div className="privacy-data mt-3">
          In consideration of your use of the platform as a restaurant/chef, you represent that you are of legal age to form a binding contract under any applicable jurisdiction. You agree to provide true, accurate, current and complete information about yourself as prompted by the registration form available on ForunPoint, and to maintain and promptly update your registration data to keep it true, accurate, current and complete. ForunPoint reserves the right to carry out the necessary verifications in order to check the identity of the Chef/restaurants. If we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, ForunPoint reserves the right to suspend or terminate your account and refuse any and all current or future use of the Platform (or any portion thereof) at any time without notice if ForunPoint believes that you have violated any provision of these terms and conditions.
          <br></br>
          Users may share personal information through the platform with other users. Any information that users share through the platform is the sole responsibility of the users themselves. Users are free to share information, but are responsible for the use of such information, its publication and disclosure. ForunPoint is not responsible for the information posted and shared through the platform. Information you provide and post through the platform may be visible to the general public.
        </div>

        <div className="privacy-title-second mt-4">Obligation:</div>
        <div className="privacy-data mt-3">
          The Chef/Restaurant agrees to provide all relevant information necessary to fulfil in supporting our client regarding food items they are offering. Restaurant understands that the Company does not exclusively work with the Restaurant. The Company has the freedom to work with other Restaurants. However, the Company shall exhibit confidentiality in all matters and professionalism while dealing with other Restaurants.
        </div>

        <div className="privacy-title-second mt-4">
          Device Return Obligation:
        </div>
        <div className="privacy-data mt-3">
          If a restaurant owner or ForunPoint decides to discontinue services using ForunPoint's online food ordering platform, they are obligated to return all devices and related equipment provided by ForunPoint in good working condition.<br></br>
          The restaurant owner must return the devices within [7 business days] of the cancellation date. Failure to return the devices within the specified timeframe may result in charges to the restaurant owner's account or other applicable penalties.<br></br>
          Devices should be returned in the working condition, accounting for reasonable wear and tear. Any damage or malfunction not attributed to normal use may incur repair or replacement charges.<br></br>
          ForunPoint reserves the right to inspect all returned devices for damage and functionality. If discrepancies are found, the restaurant owner will be notified and charged accordingly. Detailed instructions for the return process, including the return address and any other necessary information, will be provided upon the initiation of the cancellation process.
        </div>

        <div className="privacy-title-second mt-4">Service:</div>
        <div className="privacy-data mt-3">
          The Restaurant shall provide services to Client's of the Company utilizing Company platform. The Company shall, through its delivery processes, deliver the foods and beverages to the Clients.
        </div>

        <div className="privacy-title-second mt-4">CONFIDENTIALITY:</div>
        <div className="privacy-data mt-3">
          For purposes of this Agreement, "Confidential Information" shall mean information or material proprietary to a Party or designated as confidential by such Party (the “Disclosing Party”), as well as information about which a Party (the “Receiving Party”) obtains knowledge or access, through or as a result of this Agreement.
        </div>

        <div className="privacy-title-second mt-4">DISPUTE RESOLUTION:</div>
        <div className="privacy-data mt-3">
          Any dispute or difference whatsoever arising out of or in connection with this Agreement shall be solved by both parties amicably among the parties before resorting to other dispute resolution mechanisms. The parties hereby give exclusive jurisdiction to courts of competent jurisdiction to solve all disputes.
        </div>

        <div className="privacy-title-second mt-4">SEVERABILITY:</div>
        <div className="privacy-data mt-3">
          In an event where any provision of this Agreement is found to be void and unenforceable by a court of competent jurisdiction, then the remaining provisions will remain to be enforced in accordance with the Parties’ intention.
        </div>

        <div className="privacy-title-second mt-4">2.2. Account</div>

        <div className="privacy-data mt-3">
          If you register on ForunPoint, you will be required to choose a password, and you may be asked for additional information regarding your account. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify ForunPoint of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out from your account at the end of each session. You may never use another user’s account without prior authorization from ForunPoint. ForunPoint will not be liable for any loss or damage arising from your failure to comply with this agreement.<br></br>
          By providing ForunPoint with your email address, you consent to our use of your email address to send you notices and communications about the platform. We may also use your e-mail address to send you notifications and other messages, such as changes to platform features, news and special content. If you do not wish to receive these emails, you may opt-out of receiving them by sending us your removal request through our contact information or by using the "unsubscribe" option in the emails. Opting out may prevent you from receiving emails about updates, news or special content.
          <br></br>
          Users may cancel their accounts at any time and for any reason by following the instructions on the platform or by sending us their request through our contact information. That termination will only result in the deletion of the account and the deletion of all the personal data granted to ForunPoint as well as any devices provided by ForunPoint example Tablets or print device must be return back to ForunPoint within 2 days.<br></br>
          ForunPoint reserves the right to terminate your account or your access immediately, with or without notice, and without liability to you, if ForunPoint believes that you have violated any of these terms, provided ForunPoint with false or misleading information, or interfered with another user's use of the platform or service.
        </div>

        <div className="privacy-title-second mt-4">
          2.3. Services and Commissions
        </div>
        <div className="privacy-data mt-3">
          ForunPoint may provide the following services:
        </div>

        <ul className="privacy-data" >
          <li>Restaurant food delivery</li>
          <li>Gifts delivery</li>
        </ul>

        <div className="privacy-data mt-3">
          Chef/restaurants may accept requests from users and provide services according to the location of the user. <br></br>
          Chef/restaurants will pay a commission of (insert commission percentage) to ForunPoint from the total value paid by users for each service completed through the platform. Payment by users will be made through the payment processors available on the platform and will be credited to the respective Chef/restaurant's account. From each payment made for each service completed by the Chef/restaurants through the platform will be deducted the (insert commission percentage) corresponding to the commission and the remaining value will be credited to the respective Chef/restaurant. The commission value will be deducted from each payment made by users before any deduction for applicable fees or taxes.
        </div>

        <div className="privacy-title-second mt-4">3. AGE RESTRICTIONS</div>

        <div className="privacy-data mt-3">
          Use of the platform is prohibited for children under the age of 13. In th e case of minors under the age of 18 and over the age of 13, it is the responsibility of parents and legal guardians to determine whether use of the platform or any of the content and functionality available on the platform is appropriate for their child or ward. Children under the age of 18 and over the age of 13 need parental authorization to use the platform. Forun Point reserves the right to make the necessary verifications.
        </div>

        <div className="privacy-title-second mt-4">4. DRIVERS</div>
        <div className="privacy-title-second mt-4">4.1. Registration</div>
        <div className="privacy-data mt-3">
          In consideration of your use of the platform as a Driver, you represent that you are of legal age to form a binding contract under any applicable jurisdiction. You agree to provide true, accurate, current and complete information about yourself as prompted by the registration form available on Forun Point, and to maintain and promptly update your registration data to keep it true, accurate, current and complete. Forun Point reserves the right to carry out the necessary verifications in order to check the identity of the Drivers. If we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Forun Point reserves the right to suspend or terminate your account and refuse any and all current or future use of the Platform (or any portion thereof) at any time without notice if Forun Point believes that you have violated any provision of these terms and conditions.
          <br />
          Users may share personal information through the platform with other users. Any information that users share through the platform is the sole responsibility of the users themselves. Users are free to share information, but are responsible for the use of such information, its publication and disclosure. Forun Point is not responsible for the information posted and shared through the platform. Information you provide and post through the platform may be visible to the general public.
        </div>

        <div className="privacy-title-second mt-4">4.2. Account</div>
        <div className="privacy-data mt-3">
          If you register on Forun Point, you will be required to choose a password, and you may be asked for additional information regarding your account. You are responsible for maintaining the confidentiality of your password and account information, and are fully responsible for all activities that occur under your password or account. You agree to (a) immediately notify Forun Point of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you log out from your account at the end of each session. You may never use another user’s account without prior authorization from Forun Point. Forun Point will not be liable for any loss or damage arising from your failure to comply with this agreement.
          <br />
          By providing Forun Point with your email address, you consent to our use of your email address to send you notices and communications about the platform. We may also use your e- mail address to send you notifications and other messages, such as changes to platform features, news and special content. If you do not wish to receive these emails, you may opt- out of receiving them by sending us your removal request through our contact information or by using the "unsubscribe" option in the emails. Opting out may prevent you from receiving emails about updates, news or special content.
          <br />
          Users may cancel their accounts at any time and for any reason by following the instructions on the platform or by sending us their request through our contact information. That termination will only result in the deletion of the account and the deletion of all the personal data granted to Forun Point.
          <br />
          Forun Point reserves the right to terminate your account or your access immediately, with or without notice, and without liability to you, if Forun Point believes that you have violated any of these terms, provided Forun Point with false or misleading information, or interfered with another user's use of the platform or service.
          <div className="privacy-title-second mt-4">
            4.3.Services and Commissions
          </div>
          <div className="privacy-data mt-3">
            To request a service through the platform, users must follow the
            instructions below:
          </div>
        </div>

        <ul className="privacy-data" >
          <li>Food Delivery.</li>
          <li>Gift Delivery.</li>
          <li>Flowers Delivery.</li>
        </ul>

        <div className="privacy-data mt-3">
          When a user checks out an order through the platform, ForunPoint will send that user an email invoice to confirm the order. This confirmation email will be produced automatically so that the user has the confirmation of the order.
        </div>

        <div className="privacy-title-second mt-4">4.3. Payments</div>
        <div className="privacy-data mt-3">
          The services will be paid through the following payment processors:
        </div>
        <ul className="privacy-data" >
          <li>Credit Card/Debit Card</li>
          <li>M-Wallet</li>
          <li>Cash On Delivery</li>
        </ul>
        <div className="privacy-data mt-3">
          The method of displaying prices may vary based on whether:
        </div>
        <ul className="privacy-data" >
          <li>the vendor is registered for GST (Goods and Services Tax) or not.</li>
          <li>If the vendor is registered for GST, the prices displayed on the platform would include GST or any equivalent tax.</li>
          <li>However, if the vendor is not registered for GST, the displayed prices would not include GST or any equivalent tax.</li>
        </ul>
        <div className="privacy-data mt-3">
          This ensures compliance with applicable tax laws and provides transparency to customers regarding the total cost of the goods.
        </div>

        <div className="privacy-data mt-3">
          The price of the services will be determined based on the service selected by the user and the calculated distance to be covered by the Chef/restaurant Payment will be charged to the user's credit/debit card or debited from the user's PayPal account immediately after payment is made through the platform for the selected service. Once the service selection and purchase process is completed, we will issue an electronic receipt of the transaction which will be sent to the user's email address. <br></br>
          If you find any inconsistency in your billing, please contact us through our contact information or you can make the claim through the client service of the corresponding payment platform.
          If your card is declined, you will receive an error message. No payment will be charged to your card and no order will be processed. There may be a pending transaction on your account until your card issuing bank withdraws the authorization. This usually takes 2 to 5 business days. Your card may be declined for various reasons such as insufficient funds, AVS (Address Verification System) mismatch or you have entered an incorrect security code.
          <br></br>
          If your card is declined, you will receive an error message. No
          payment will be charged to your card and no order will be processed.
          There may be a pending transaction on your account until your card
          issuing bank withdraws the authorization. This usually takes 2 to 5
          business days. Your card may be declined for various reasons such as
          insufficient funds, AVS (Address Verification System) mismatch or you
          have entered an incorrect security code.<br></br>
          If your payment is declined, you must provide an alternative payment
          method or provide another card where payment can be charged and
          processed.<br></br>
          Your payment details will be processed and stored securely and for the
          sole purpose of processing the booking and purchase of services
          through the platform. ForunPoint reserves the right to contract any
          payment platform available on the market, which processes your data
          for the sole purpose of processing the booking and purchase of
          services through the platform.
          <br />
          <br />
          Delivery fees are applicable by default on every order unless:
          <br />
          <ul className="privacy-data" >
            <li>You choose to collect your order directly from the vendor ("Pick-Up").</li>
            <li>You possess a valid promotional or discount voucher and apply it during the checkout process.</li>
            <li>It is explicitly stated otherwise.</li>
          </ul>
          These conditions clarify when customers may be exempted from delivery fees, providing transparency and flexibility in the ordering process.
        </div>

        <div className="privacy-title-second mt-4">5. DISCLAIMER</div>
        <div className="privacy-data mt-3">
          The services provided by the Chef/restaurants through the platform are not offered or provided by ForunPoint. ForunPoint's services are limited solely to providing the platform to facilitate the collection and provision of the services provided by the Chef/restaurants. The users of the Services and the Chef/restaurants are users of the ForunPoint platform and at no time is there any employment or working relationship with the Chef/restaurants. The services offered and accepted by the Chef/restaurants through the platform are the sole and exclusive responsibility of the Chef/restaurants. ForunPoint is at no time responsible for the services offered and provided by the Chef/restaurants through the platform.<br></br>
          ForunPoint is not liable for damages to the physical or moral integrity of persons, such as injury, death or any other moral damage such as threats, insults and slander that may fall on a natural person, as a result of the communications established on the platform. The communications and relationships established between users as a result of any connection within the platform are the sole and exclusive responsibility of the users.<br></br>
          In the event that one or more users or any third party initiates any type of claim or legal action against another or other users, each and every one of the users involved in such claims or actions exempt ForunPoint from any liability.<br></br>
          By visiting the website and accessing the content available on the website (blog), you accept personal responsibility for the results of the use of the information and content available on the website. You agree that ForunPoint does not guarantee the results of actions advised or not advised by this website and the content available on the website. ForunPoint provides resources and content for informational purposes only. You acknowledge and agree that your ultimate success or failure in using the information and content available on the website will be the result of your own efforts, your particular situation and a number of other circumstances that are beyond ForunPoint's control. This disclaimer is not intended to exclude any warranty implied by law that cannot be legally excluded.
        </div>

        <div className="privacy-title-second mt-4">
          6. THIRD-PARTY MATERIALS.
        </div>
        <div className="privacy-data mt-3">
          “Third-Party Materials” means any content, images, videos, texts or other material that is owned by a third party, such as stock images, videos and texts. Such Third-Party Materials are subject to the applicable third-party terms and licenses, and may only be used as permitted by such terms and licenses.
        </div>

        <div className="privacy-title-second mt-4">7. ADVERTISING</div>
        <div className="privacy-data mt-3">
          Through the platform, ForunPoint may make available to users, commercial and advertising information, own or third parties in accordance with good business practices. In these cases, ForunPoint does not endorse, guarantee or commit its responsibility for the services and/or products marketed by these third parties, since the platform serves as a channel of communication and advertising, but not as a tool for the provision of services. Consequently, it is the full responsibility of the users to access the sites that refer to advertising, assuming the obligation to verify and know the terms of the services offered by third parties.
        </div>

        <div className="privacy-title-second mt-4">
          8. LICENSE TO USE THE PLATFORM
        </div>
        <div className="privacy-data mt-3">
          ForunPoint gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the platform (software) provided to you by ForunPoint as part of the services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the services as provided by ForunPoint, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.<br></br>
          The user agrees not to use the platform and the services negligently, for fraudulent purposes or in an unlawful manner. Likewise, the user agrees not to partake in any conduct or action that could damage the image, interests or rights of the ForunPoint platform or third parties.
          <br></br>
          This service prohibits sending of messages, that: (1) Any kind of messages that are cataloged as SPAM. (2) Are harassing, abusive, defamatory, obscene, in bad faith, unethical or otherwise illegal content (3) distribute trojans, viruses or other malicious computer software (4) Any message with political or religious content (5) Any message with obscene or offensive content (6) Are intending to commit fraud, impersonating other persons, phishing, scams, or related crime (7) distribute intellectual property without ownership or a license to distribute such property (8) Breach, in any way, the terms of service, privacy policy or rules of this platform or the recipients.<br></br>
          ForunPoint reserves the right to terminate your access immediately, with or without notice, and without liability to you, if ForunPoint believes that you have violated any of these terms or interfered with the use of the platform or service by others.
        </div>

        <div className="privacy-title-second mt-4">9. COPYRIGHT</div>
        <div className="privacy-data mt-3">
          All materials on ForunPoint, including, without limitation, names, logos, trademarks, images, text, columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights, trademarks and/or other intellectual property rights owned and controlled by ForunPoint or by third parties that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all Materials on ForunPoint are made available for limited, non-commercial, personal use only. Except as specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted, transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without ForunPoint prior express written permission. You may not add, delete, distort, or otherwise modify the material. Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize ForunPoint or any part of the material for any purpose other than its intended purposes is strictly prohibited.
          <br />
          Any violation of trademark or copyright laws will result in legal action, including but not limited to cease and desist notices, litigation, and financial penalties  in case of violation the company shall take strict action according to law.
        </div>

        <div className="privacy-title-second mt-4">
          10. COPYRIGHT INFRINGEMENT
        </div>
        <div className="privacy-data mt-3">
          ForunPoint will respond to all inquiries, complaints and claims regarding alleged infringement for failure to comply with or violation of the provisions contained in the ForunPoint. ForunPoint respects the intellectual property of others, and expects users to do the same. If you believe, in good faith, that any material provided on or in connection with the website infringes your copyright or other intellectual property right, please send us your copyright infringement request via our contact information, with the following information:
        </div>
        <ul className="privacy-data" >
          <li>
            Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.
          </li>
          <li>
            A statement that specifically identifies the location of the infringing material, in sufficient detail so that ForunPoint can find it on the platform. Note that providing a top- level URL is not sufficient.
          </li>
          <li>Your name, address, telephone number and email address.</li>
          <li>
            A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.
          </li>
          <li>
            An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner's behalf.
          </li>
        </ul>

        <div className="privacy-title-second mt-4">11. PERSONAL DATA</div>
        <div className="privacy-data mt-3">
          Any personal information you post or submit in connection with the services and use of the platform will be used in accordance with our privacy policy. Please see our privacy policy.
        </div>

        <div className="privacy-title-second mt-4">
          12. PROHIBITED ACTIVITIES
        </div>
        <div className="privacy-data mt-3">
          The content and information available on the platform (including, but not limited to, data, information, text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure used to provide such content and information, is proprietary to ForunPoint or licensed to the ForunPoint by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software or services obtained from or through the platform. In addition, the following activities are prohibited:
        </div>

        <ul className="privacy-data" >
          <li>
            Use the services or content for any commercial purpose, outside the scope of those commercial purposes explicitly permitted under this agreement and related guidelines as made available by ForunPoint.
          </li>
          <li>
            Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.
          </li>
          <li>
            Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.
          </li>
          <li>
            Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.
          </li>
          <li>
            Deep-link to any portion of the services for any purpose without our express written permission.
          </li>
          <li>
            "Frame", "mirror" or otherwise incorporate any part of the services into any other platforms or service without our prior written authorization.
          </li>
          <li>
            Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by ForunPoint in connection with the services.
          </li>
          <li>
            Circumvent, disable or otherwise interfere with security-related features of the services or features that prevent or restrict use or copying of any content.
          </li>
          <li>
            Download any content unless it’s expressly made available for download by ForunPoint.
          </li>
        </ul>

        <div className="privacy-title-second mt-4">
          13. DISCLAIMER OF WARRANTIES
        </div>
        <div className="privacy-data mt-3">
          ForunPoint will provide its services with reasonable skill and care but does not give any guarantees, warranties or representations in respect of any other person's services.<br></br>
          Because of the nature of the Internet ForunPoint provides and maintains the platform on an "as is", "as available" basis and makes no promise that use of the platform will be uninterrupted or entirely error free. We are not responsible to you if we are unable to provide our Internet services for any reason beyond our control.
          <br></br>
          Our platform may from time to time contain links to other websites which are not under the control of and are not maintained by us. These links are provided for your convenience only and we are not responsible for the content of those platforms.<br></br>
          Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.
          <br></br>
          You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse ForunPoint for any loss or damage caused as a result.
          <br></br>
          ForunPoint will not be liable in any amount for failure to perform any obligation under this agreement if such failure is caused by the occurrence of any unforeseen event beyond its reasonable control including without limitation Internet outages, communications outages, fire, flood, war or act of God.<br></br>
          These terms do not affect your statutory rights as a consumer which are available to you.<br></br>
          Subject as aforesaid, to the maximum extent permitted by law, ForunPoint excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to ForunPoint and ForunPoint shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:
        </div>

        <ul className="privacy-data" >
          <li>Any incorrect or inaccurate information on the platform.</li>
          <li>
            The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the platform or any product or service purchased through the platform.
          </li>
          <li>
            Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.
          </li>
          <li>
            Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.
          </li>
          <li>
            Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from ForunPoint.
          </li>
          <li>
            All representations, warranties, conditions and other terms which but for this notice would have effect.
          </li>
        </ul>

        <div className="privacy-title-second mt-4">
          14. ELECTRONIC COMMUNICATIONS
        </div>
        <div className="privacy-data mt-3">
          No responsibility will be accepted by ForunPoint for failed, partial or garbled computer transmissions, for any computer, telephone, cable, network, electronic or internet hardware or software malfunctions, failures, connections, availability, for the acts or omissions of any user, internet accessibility or availability or for traffic congestion or unauthorized human act, including any errors or mistakes.
        </div>

        <div className="privacy-title-second mt-4">15. INDEMNIFICATION</div>
        <div className="privacy-data mt-3">
          You agree to defend and indemnify ForunPoint from and against any claims, causes of action, demands, recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
        </div>

        <ul className="privacy-data" >
          <li>
            Your breach of this agreement or the documents referenced herein.
          </li>
          <li>Your violation of any law or the rights of a third party.</li>
          <li>Your use of the platform.</li>
        </ul>

        <div className="privacy-title-second mt-4">
          16. CHANGES AND TERMINATION
        </div>
        <div className="privacy-data mt-3">
          We may change the platform and these terms at any time, in our sole discretion and without notice to you. You are responsible for remaining knowledgeable about these terms. Your continued use of the site constitutes your acceptance of any changes to these Terms and any changes will supersede all previous versions of the terms. Unless otherwise specified herein, all changes to these Terms apply to all users take effect. Furthermore, we may terminate this agreement with you under these Terms at any time by notifying you in writing (including by email) or without any warning.
        </div>

        <div className="privacy-title-second mt-4">17. ASSIGNMENT</div>
        <div className="privacy-data mt-3">
          This agreement and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by ForunPoint without restriction.
        </div>

        <div className="privacy-title-second mt-4">18. INTEGRATION CLAUSE</div>
        <div className="privacy-data mt-3">
          This agreement together with the privacy policy and any other legal notices published by ForunPoint, shall constitute the entire agreement between you and ForunPoint concerning and governs your use of the platform and the services.
        </div>

        <div className="privacy-title-second mt-4">19. Promotional Coupons and Discounts</div>
        <div className="privacy-data mt-3">
          ForunPoint periodically conducts marketing campaigns offering promotional Coupons, which include coupon codes, discounts, and other offers for use on the platform. These Coupons are subject to validity periods, redemption terms, usage restrictions, and other conditions.<br></br>
          Coupons may not be valid in conjunction with other promotions, discounts, or vouchers. Additional terms and conditions, including vendor-specific terms, may apply to the use of Coupons.<br></br>
          Unless otherwise specified, Coupons are only usable on the ForunPoint platform.<br></br>
          Coupons are non-transferable, non-exchangeable, non-refundable, and cannot be redeemed for cash.<br></br>
          ForunPoint reserves the right to withdraw, modify, or amend the terms and conditions related to Coupons at any time without prior notice. Additionally, ForunPoint reserves the right to void, discontinue, or reject the use of any Coupon without prior notice.
        </div>

        <div className="privacy-title-second mt-4">20. DISPUTES</div>
        <div className="privacy-data mt-3">
          The user agrees that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the platform, shall be resolved by binding arbitration between the user and ForunPoint, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.
          In the event a dispute arises in connection with the use of the platform or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.
          <br></br>
          You agree to initiate a formal dispute proceeding by sending us a communication through our contact information. ForunPoint may choose to send you a written offer after receiving your initial communication. If we offer and send you a settlement offer and you do not accept the offer, or we are unable to resolve your dispute satisfactorily and you wish to continue with the dispute process, you must initiate the dispute resolution process before an accredited arbitration organization and file a separate Demand for Arbitration. Any award rendered by the arbitration tribunal shall be final and conclusive on the parties.<br></br>
          To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the platform.<br></br>
          The courts of Pakistan, specifically the courts located in Punjab, Pakistan, shall have jurisdiction over any dispute, controversy or claim relating to ForunPoint and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of Pakistan, specifically the courts located in Punjab, Pakistan.<br></br>
          <u className="jur" >Jurisdiction </u>:
          Any disputes arising from violations of trademark or copyright laws shall be governed by the laws of the State, and the courts of competent jurisdiction.
        </div>
        <div className="privacy-title-second mt-4">21. Gifts and Flowers Delivery</div>
        <div className="privacy-data mt-3">
          1. ForunPoint offers same-day delivery service only in Lahore for now. You can order anything from anywhere across the world to make sure it’s delivered in Lahore. Although, we are planning to extend our food and gift delivery services to other cities as well. Please note, that same-day delivery is also available on special occasions such as Mother’s Day, Eid, and other major holidays.<br></br>
          2. Orders will be processed once authorization has been received from the credit card processing company.<br></br>
          3. All transactions processed in USD will be billed by our U.S. merchant account and will appear as “ForunPoint.com” on your credit card statement. Foreign currency transactions will be billed in the customer’s local currency, as shown on our website.<br></br>
          4. Orders cannot be canceled once they have been placed, as they are immediately booked with suppliers directly.<br></br>
          5. ForunPoint reserves the right to cancel any order for the following reasons:
          •	Failure of the customer to provide requested verification or respond to contact attempts.
          •	The order fails security checks.<br></br>
          6. While every effort is made to deliver the exact product ordered, circumstances beyond our control (e.g., regional or seasonal limitations) may prevent this. In such cases, we may offer a suitable substitute product of equal or greater value.<br></br>
          7. The images displayed on our website are for illustrative purposes only. As we are covering a wide range of restaurants across Lahore and planning to expand to other cities, each city may have variations in product presentation (e.g., food, flowers, fruit baskets). Therefore, the actual product may differ from the image shown. However, we guarantee the quality and quantity as described on the website.<br></br>
          8. In case of unavailability, we reserve the right to provide substitute products of equal or greater value. This may include replacing roses with mixed flowers, flower colors, cake flavors, or even splitting larger cakes into smaller ones.<br></br>
          9. While customers may leave special instructions regarding delivery times, cake flavors, fruit choices, etc., we do not guarantee the fulfillment of these requests. No refunds or cancellations will be made if a request is not honored.<br></br>
          10. Same-day delivery is available for orders placed before 1:00 PM Pakistan Standard Time.<br></br>
          11. In cases of supply challenges, we may adjust delivery items or times. We reserve the right to change flower colors, and cake flavors, and deliver items at separate times if necessary.<br></br>
          12. We work with top suppliers across Pakistan. If you are dissatisfied with the quality of a product, please contact us within two days of delivery, along with your order number and details. Only replacements will be offered—no refunds. The product must be returned unused and in its original condition. Please note, that complaints regarding meals and items from food chains will not be entertained due to the nature of the products.<br></br>
          13. Refunds are only provided if we fail to deliver the product on the desired delivery date. If an order is delayed due to incorrect address or phone number details provided by the customer, no refund will be issued. In such cases, we will attempt redelivery the following day, but freshness cannot be guaranteed, voiding product warranties.<br></br>
          14. While we strive to deliver orders within standard business hours, unforeseen circumstances such as traffic, supplier delays, weather conditions, or law and order issues may cause delays. Delivery times are not guaranteed but will be completed within office hours whenever possible.<br></br>
          15. By placing an order, you consent to receive promotional emails from us regarding special offers and events.<br></br>
          16. Our delivery hours are between 12:00 PM and 2 AM Pakistan Standard Time.

        </div>

        <div className="privacy-title-second mt-4">22. FINAL PROVISIONS</div>
        <div className="privacy-data mt-3">
          These terms and conditions are governed by the laws of the Land. Use of the platform is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions.
          <br></br>
          Our performance of these Terms is subject to existing laws and legal process, and nothing contained in these Terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our platform or information provided to or gathered by us with respect to such use.<br></br>
          If any part of these Terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these Terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.<br></br>
          Any rights not expressly granted herein are reserved.
        </div>

        <div className="privacy-title-second mt-4">23. CONTACT INFORMATION</div>
        <div className="privacy-data mt-3">
          If you have questions or concerns about these terms, please contact us through our contact page or via the contact information below:
        </div>
        <br />
        <div className="privacy-email">Email: <a href="mailto:info@forunpoint.com.pk"> info@forunpoint.com.pk</a></div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
