import React from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/footer";
import { FiSearch } from "react-icons/fi";
import Slider from "../slider/slider";
import "./blog.css";
import Voice from '../../../assets/images/Voice.jpg'
import Gift from '../../../assets/images/Gift.jpg'
import FpSucess from '../../../assets/images/third.jpeg'
import LhrBlog from '../../../assets/images/LhrBlog.jpg'
import JtBlog from '../../../assets/images/JtBlog.jpg'
import BtBlog from '../../../assets/images/BtBlog.jpg'



function blog() {
  return (
    <div>
      <Navbar /> <h1 className="our-blogs">Blogs</h1>
      <div class="card-container">
        <div class="card">
          <a href="/blog/Voice-Command-Food-Ordering" class="card-link">
            <img src={Voice} alt="Card 1 Image" class="card-image" />
            <h3>ForunPoint’s Solution with Voice Command Food Ordering</h3>
          </a>
        </div>
        <div class="card">
          <a href="/blog/Spreading-Joy-&-Gift-at-a-Time" class="card-link">

            <img src={Gift} alt="Card 2 Image" class="card-image" />
            <h3>ForunPoint Spreading Joy, One Gift at a Time</h3>
          </a>

        </div>
        <div class="card">
          <a href="/blog/ForunPoint-Blueprint-for-Success" class="card-link">

            <img src={FpSucess} alt="Card 3 Image" class="card-image" />
            <h3>ForunPoint's Blueprint for Success in 2024</h3>
          </a>

        </div>
        <div class="card">
          <a href="/blog/Online-Food-Delivery-in-Lahore" class="card-link">

            <img src={LhrBlog} alt="Card 1 Image" class="card-image" />
            <h3>Online Food Delivery in Lahore: Convenience at Your Doorstep</h3>
          </a>

        </div>
        <div class="card">
          <a href="/blog/Top-Cafes-in-Johar-Town-Lahore" class="card-link">

            <img src={JtBlog} alt="Card 2 Image" class="card-image" />
            <h3>Top Cafes in Johar Town Lahore: Best Places to Visit</h3>
          </a>

        </div>
        <div class="card">
          <a href="/blog/Top-Rated-Restaurants-in-Bahria-Town-Lahore-for-Dining" class="card-link">

            <img src={BtBlog} alt="Card 3 Image" class="card-image" />
            <h3>Top-Rated Restaurants in Bahria Town Lahore for Dining</h3>
          </a>

        </div>
      </div>


      <div className="announcements">
        <h2 className="announcement-title">Official Announcements</h2>
        {/* <Slider /> */}
        <p className="container announcement-desc">
          Looking ahead, we are excited to continue this trajectory of growth and innovation, further solidifying our commitment to providing unparalleled service to our customers and partners.


          <br /><br />
          Thank you to our dedicated team, loyal customers, and supportive partners for making 2024 a truly successful year for ForunPoint.
        </p>
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
}

export default blog;
