import React, { useState } from "react";
import "./app-popup.css";
import Apple from "../../../assets/images/iphone13.png"
import Android from "../../../assets/images/Generic.png"
import AppleStore from "../../../assets/images/AppStoreBlack.png"
import PlayStore from "../../../assets/images/PlayStoreBlack.png"
const AppDownloadPopup = ({ onClose }) => {
    const [isClosing, setIsClosing] = useState(false);

    // Handle close with animation
    const handleClose = () => {
        setIsClosing(true); // Trigger slide-out animation
        setTimeout(() => {
            onClose(); // Close the popup after the animation ends
        }, 1500); // Match the duration of the slideOut animation (0.5s)
    };
    return (
        // <div className="popup-container">
        <div className={`popup-container ${isClosing ? "slide-out" : ""}`}>
            <div className="popup-content popup-content-app ">
                <div className="images-content">
                    <img
                        src={Apple}
                        alt="App Image 1"
                        className="app-image"
                    />
                    <img
                        src={Android}
                        alt="App Image 2"
                        className="app-image android-phone"
                    />
                </div>
                <div className="text-content">
                    <h3>Available on IOS & Android</h3>
                    <h2>Download Our App</h2>
                    <p>
                        Download the ForunPoint app now and get instant order of food and gift delivery!
                    </p>
                    <a
                        href="https://apps.apple.com/us/app/forunpoint/id1630196197"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={AppleStore} alt="" className="store-black-icons" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.whetcloud.forunpoint.user"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={PlayStore} alt="" className="playstore-black-icons" />
                    </a>
                </div>
                <button className="close-btn" onClick={handleClose}>
                    &times;
                </button>
            </div>
        </div>
    );
};

export default AppDownloadPopup;
