// import React from 'react';
// import { HiArrowLongLeft } from "react-icons/hi2";
// import { HiArrowLongRight } from "react-icons/hi2";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import './updates.css';
// import Voice from '../../../assets/images/Voice.jpg'
// import Gift from '../../../assets/images/Gift.jpg'
// import FpSucess from '../../../assets/images/third.jpeg'
// import LhrBlog from '../../../assets/images/LhrBlog.jpg'
// import JtBlog from '../../../assets/images/JtBlog.jpg'
// import BtBlog from '../../../assets/images/BtBlog.jpg'


// const CustomPrevArrow = (props) => (
//   <div {...props} className="custom-arrow2 prev-arrow2">
//     <HiArrowLongLeft />
//   </div>
// );

// const CustomNextArrow = (props) => (
//   <div {...props} className="custom-arrow2 next-arrow2">
//     <HiArrowLongRight />
//   </div>
// );

// function Updates() {
//   const settings = {
//     centerMode: false,
//     infinite: true,
//     dots: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     arrows: true,
//     prevArrow: <CustomPrevArrow />,
//     nextArrow: <CustomNextArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         }
//       },
//       {
//         breakpoint: 767,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           initialSlide: 1
//         }
//       }
//     ]
//   };
//   return (
//     <div className='updatesec'>
//       <div className='updates-section'>
//         <h3 className="updates-heading my-5 text-center">Our Updates</h3>
//         <Slider {...settings} className="carousel slider-updates">
//           <div className="card-background">
//             <div className="update-card">
//               <a href='/blogs'>
//                 <div className="card-body">
//                   <img
//                     className="updates-section-img"
//                     src={Gift}
//                     alt="Gifts"
//                   />
//                 </div>
//               </a>
//               <div className="update-card-title">
//                 <a className='update-link' href='/blogs'>ForunPoint Spreading Joy, One Gift at a Time</a>
//               </div>
//             </div>
//           </div>
//           <div className="card-background">
//             <div className="update-card">
//               <a href='/blogs'>
//                 <div className="card-body">
//                   <img
//                     className="updates-section-img"
//                     src={Voice}
//                     alt="Voice"
//                   />
//                 </div>
//               </a>
//               <div className="update-card-title">
//                 <a className='update-link' href='/blogs'>ForunPoint’s Solution with Voice Command Food Ordering</a>
//               </div>
//             </div>
//           </div>
//           <div className="card-background">
//             <div className="update-card">
//               <a href='/blogs'>
//                 <div className="card-body">
//                   <img
//                     className="updates-section-img"
//                     src={FpSucess}
//                     alt="Blogs"
//                   />
//                 </div>
//               </a>
//               <div className="update-card-title">
//                 <a className='update-link' href='/blogs'>ForunPoint's Blueprint for Success in 2024</a>
//               </div>
//             </div>
//           </div>
//           <div className="card-background">
//             <div className="update-card">
//               <a href='/blogs'>
//                 <div className="card-body">
//                   <img
//                     className="updates-section-img"
//                     src={LhrBlog}
//                     alt=""
//                   />
//                 </div>
//               </a>
//               <div className="update-card-title">
//                 <a className='update-link' href='/blog'>Online Food Delivery in Lahore: Delicious Meals Made Easy</a>
//               </div>
//             </div>
//           </div>
//           <div className="card-background">
//             <div className="update-card">
//               <a href='/blogs'>
//                 <div className="card-body">
//                   <img
//                     className="updates-section-img"
//                     src={JtBlog}
//                     alt=""
//                   />
//                 </div>
//               </a>
//               <div className="update-card-title">
//                 <a className='update-link' href='/blog'>Top Cafes in Johar Town Lahore: Best Places to Visit</a>
//               </div>
//             </div>
//           </div>
//           <div className="card-background">
//             <div className="update-card">
//               <a href='/blogs'>
//                 <div className="card-body">
//                   <img
//                     className="updates-section-img"
//                     src={BtBlog}
//                     alt=""
//                   />
//                 </div>
//               </a>
//               <div className="update-card-title">
//                 <a className='update-link' href='/blog'>Top-Rated Restaurants in Bahria Town Lahore for Dining</a>
//               </div>
//             </div>
//           </div>
//         </Slider>
//       </div>
//     </div>
//   );
// }

// export default Updates;

import React from 'react';
import Slider from 'react-slick';
import './updates.css';
// import Voice from '../../../assets/images/Voice.jpg'
// import Gift from '../../../assets/images/Gift.jpg'
// import FpSucess from '../../../assets/images/third.jpeg'
import LhrBlog from '../../../assets/images/LhrBlog.jpg'
import JtBlog from '../../../assets/images/JtBlog.jpg'
import BtBlog from '../../../assets/images/BtBlog.jpg'


// const CustomArrow = ({ className, style, onClick, type }) => (
//   <div
//     className={`${className} custom-arrow ${type}`}
//     style={{ ...style, position: 'absolute', top: '10px', left: type === 'prev' ? '10px' : '50px', zIndex: 2 }}
//     onClick={onClick}
//   >
//     {type === 'prev' ? '<' : '>'}
//   </div>
// );

// const ResponsiveCarousel = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     nextArrow: <CustomArrow type="next" />,
//     prevArrow: <CustomArrow type="prev" />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div style={{ margin: '0 auto', maxWidth: '80%' }}>
//       <h2>Responsive Carousel</h2>
//       <Slider {...settings}>
//         <div className="carousel-slide">
//           <img src="https://via.placeholder.com/300" alt="Slide 1" />
//         </div>
//         <div className="carousel-slide">
//           <img src="https://via.placeholder.com/300" alt="Slide 2" />
//         </div>
//         <div className="carousel-slide">
//           <img src="https://via.placeholder.com/300" alt="Slide 3" />
//         </div>
//         <div className="carousel-slide">
//           <img src="https://via.placeholder.com/300" alt="Slide 4" />
//         </div>
//       </Slider>
//     </div>
//   );
// };

// export default ResponsiveCarousel;

// const CustomArrow = ({ className, style, onClick, type }) => (
//   <div
//     className={`${className} custom-arrow ${type}`}
//     style={{ ...style, position: 'absolute', top: '10px', left: type === 'prev' ? '10px' : '50px', zIndex: 2 }}
//     onClick={onClick}
//   >
//     {type === 'prev' ? '<' : '>'}
//   </div>
// );
const CustomArrow = ({ className, style, onClick, type }) => (
  <div
    className={`${className} custom-carousel-arrow custom-${type}-arrow`}
    style={{
      ...style,
      position: 'absolute',
      top: '-30px', // Position arrows above the slider
      left: type === 'prev' ? '10px' : '50px', // Adjust positioning for "prev" and "next"
      transform: 'none', // No need for vertical centering
      zIndex: 2,
    }}
    onClick={onClick}
  >
    {/* {type === 'prev' ? '<' : '>'} */}
  </div>
);

const ResponsiveCarousel = () => {
  const slides = [
    {
      imgSrc: LhrBlog,
      title: 'Online Food Delivery in Lahore: Delicious Meals Made Easy',
      date: 'Jul-2024',
      href: '/blogs',
    },
    {
      imgSrc: JtBlog,
      title: 'Top Cafes in Johar Town Lahore: Best Places to Visit',
      date: 'Jul-2024',
      href: '/blogs',
    },
    {
      imgSrc: BtBlog,
      title: 'Top-Rated Restaurants in Bahria Town Lahore for Dining',
      date: 'Jul-2024',
      href: '/blogs',
    },
    // {
    //   imgSrc: Gift,
    //   title: 'Slide 4 Title',
    //   date: '2025-01-06',
    //   href: '#slide4',
    // },
    // {
    //   imgSrc: LhrBlog,
    //   title: 'Slide 4 Title',
    //   date: '2025-01-06',
    //   href: '#slide4',
    // },
  ];

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   nextArrow: <CustomArrow type="next" />,
  //   prevArrow: <CustomArrow type="prev" />,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow type="next" />,
    prevArrow: <CustomArrow type="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  

  return (
    <div style={{ paddingTop: '40px', maxWidth: '100%',  backgroundColor: '#f0f0f0' }}>

    <div style={{ margin: '0 auto', maxWidth: '80%' }}>
      <h2>Our Updates</h2>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="carousel-slide" key={index}>
            <img className='our-updates-slider-img' src={slide.imgSrc} alt={slide.title} />
            <div className="slide-content">
              <h3>{slide.title}</h3>
              <p>{slide.date}</p>
              <a href={slide.href}>Read More</a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default ResponsiveCarousel;
